import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { CaseStudies, ContentWithLink, Logos, NavBarColorSwitcher, Spacer, StatementHero, WhatWeDo, Cta } from "components";
import fusebox from "images/client-logos/fusebox.svg";
import gpd from "images/client-logos/gpd.svg";
import insanelab from "images/client-logos/insanelab.svg";
import thecamels from "images/client-logos/thecamels.svg";
import tnbt from "images/client-logos/tnbt.svg";
import wpserved from "images/client-logos/wpserved.svg";
import wupwt from "images/client-logos/wupwt.png";
import { graphql } from "gatsby";
export const query = graphql`
	query CASE_STUDIES {
		allMdx(
			filter: { frontmatter: { slug: { regex: "/^/case-studies//i" } } }
			sort: { fields: [frontmatter___date], order: DESC }
		) {
			edges {
				node {
					id
					body
					frontmatter {
						slug
						title
						date
						image {
							childImageSharp {
								gatsbyImageData
							}
						}
						image_title
						tagline
						contract
						country
						timeline
						tags
					}
				}
			}
		}
	}
`;
export const props = {};
export const _frontmatter = {
  "title": "Our Work",
  "navBarColor": "light"
};
const layoutProps = {
  query,
  props,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <StatementHero backLineOffset={235} backLines={["We are", "DIFFerent"]} backList={["Develop.", "Iterate.", "Formalize.", "Feast."]} brushSize={138} frontLines={["Some companies", "do DIFF"]} frontList={["Develop.", "Invoice.", "Fake.", "Forget."]} minBrushSize={70} mdxType="StatementHero" />
    <NavBarColorSwitcher color="dark" mdxType="NavBarColorSwitcher">
	<Spacer height={150} responsive={{
        md: 120,
        sm: 100
      }} mdxType="Spacer" />
	<WhatWeDo backgroundText="OUR VALUES" items={["Detailed designs", "Pixel-perfect implementation", "Software easy to maintain ", "Experienced team", "No spaghetti-code ", "No code debt"]} text="We <strong>create software</strong> that matters." title="Our work" mdxType="WhatWeDo" />
	<CaseStudies props={props} mdxType="CaseStudies" />
	<Logos logos={[{
        image: gpd,
        title: "Good People Digital"
      }, {
        image: insanelab,
        title: "InsaneLab"
      }, {
        image: thecamels,
        title: "The Camels"
      }, {
        image: wpserved,
        title: "WP Served"
      }, {
        image: fusebox,
        title: "Fusebox"
      }, {
        image: tnbt,
        title: "TNBT"
      }, {
        image: wupwt,
        title: "Wojewódzki Urząd Pracy w Toruniu"
      }]} logosAlign="center" title="Over 265+ client projects" titleAlign="center" mdxType="Logos" />
	<Spacer height={150} responsive={{
        md: 120,
        sm: 100
      }} mdxType="Spacer" />
	<Cta title="Take partnership to the next level" subtitle="Some clients stay with us for over 4 years. With our experienced team,  we’re ready to assist you with full-cycle development" buttonText="Get in touch" to="/contact" mdxType="Cta" />
	<Spacer height={150} responsive={{
        md: 120,
        sm: 100
      }} mdxType="Spacer" />
	<ContentWithLink button={{
        children: "Talk with Chef",
        to: "/contact"
      }} content="The Department of Web is a software house cooking delicious products based on WordPress or Laravel broth. We&nbsp;can add a bit of React for extra flavor." headline="You know what you like and **we know how** to cook it." headlineAlign="left" mdxType="ContentWithLink" />
	<Spacer height={150} responsive={{
        md: 120,
        sm: 100
      }} mdxType="Spacer" />
    </NavBarColorSwitcher>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      